<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Rutas</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Tif</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Basico</li>
                  <li class="breadcrumb-item active">Rutas</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="col-6 pt-2 pb-2">
                <h5 class="mb-0">Formulario {{ accion }} Rutas</h5>
              </div>
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="tab-Principal"
                    data-toggle="tab"
                    href="#Principal"
                    >Principal</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="tab-Principal"
                    data-toggle="tab"
                    href="#Sitios"
                    v-if="form.id != null"
                    >Sitios</a
                  >
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade active show" id="Principal">
                  <div class="card-body">
                    <div class="row">
                      <div class="form-group col-md-4">
                        <label for="nombre">Nombre Ruta</label>
                        <input
                          type="text"
                          maxlength="100"
                          class="form-control form-control-sm"
                          id="nombre"
                          v-model="form.nombre"
                          :class="
                            $v.form.nombre.$invalid ? 'is-invalid' : 'is-valid'
                          "
                        />
                      </div>
                      <div class="form-group col-md-4">
                        <label>Bloque</label>
                        <v-select
                          :class="[
                            $v.form.bloque_id.$invalid
                              ? 'is-invalid'
                              : 'is-valid',
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                          ]"
                          v-model="bloque"
                          placeholder="Bloque"
                          label="nombre"
                          :options="listasForms.bloques"
                          @input="selectBloque()"
                          class="form-control form-control-sm p-0"
                        ></v-select>
                      </div>
                      <div class="form-group col-md-2">
                        <label>Estado</label>
                        <select
                          class="form-control form-control-sm"
                          v-model="form.estado"
                          :class="
                            $v.form.estado.$invalid ? 'is-invalid' : 'is-valid'
                          "
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="Sitios">
                  <div class="card-body">
                    <TifRutasSitio
                      ref="TifRutasSitio"
                      v-if="form.id != null"
                    ></TifRutasSitio>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                  <button
                    class="btn bg-primary col-md-1"
                    v-show="!$v.form.$invalid"
                    @click="save()"
                    v-if="$store.getters.can('tif.rutas.create')"
                  >
                    <i class="fas fa-paper-plane"></i><br />Guardar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import { required } from "vuelidate/lib/validators";
import vSelect from "vue-select";
import TifRutasSitio from "./TifRutasSitio";

export default {
  name: "TifRutasForm",
  components: {
    vSelect,
    Loading,
    TifRutasSitio,
  },
  data() {
    return {
      cargando: false,
      form: {
        nombre: null,
        bloque_id: null,
        estado: null,
      },
      bloque: [],
      accion: "",
      metodo: "",
      listasForms: {
        estados: [],
        bloques: [],
      },
      id: null,
    };
  },
  validations: {
    form: {
      nombre: {
        required,
      },
      bloque_id: {
        required,
      },
      estado: {
        required,
      },
    },
  },
  methods: {
    init() {
      this.cargando = true;
      this.accion = this.$route.params.accion;
      this.form.id = this.$route.params.id;
      if (this.$route.params.accion == "Editar") {
        this.form = this.$route.params.data_edit;
        this.metodo = "PUT";
        this.form.id = this.$route.params.id;
        this.bloque = {
          id: this.form.bloque_id,
          nombre: this.form.bloque.nombre,
        };
      } else {
        this.metodo = "POST";
        this.form.id = null;
      }
      this.cargando = false;
    },

    getEstados() {
      axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    getBloques() {
      axios.get("/api/admin/bloques/lista").then((response) => {
        this.listasForms.bloques = response.data;
      });
    },

    selectBloque() {
      this.form.bloque = {};
      this.form.bloque_id = "";
      if (this.bloque) {
        this.form.bloque = this.bloque;
        this.form.bloque_id = this.bloque.id;
      }
    },

    save() {
      this.cargando = true;
      if (!this.$v.form.$invalid) {
        let datos = {};
        if (this.$route.params.accion == "Editar") {
          datos = {
            tif_ruta_id: this.$refs.TifRutasSitio.tif_ruta_id,
            form: this.form,
          };
        } else {
          datos = {
            form: this.form,
          };
        }
        axios({
          method: this.metodo,
          url: "/api/tif/rutas",
          data: datos,
        })
          .then((response) => {
            this.form = response.data;
            this.id = this.$route.params.id;
            if (this.$route.params.accion == "Editar") {
              this.$refs.TifRutasSitio.getIndex();
            }
            this.$route.params.accion = "Editar";
            this.id = this.form.id;
            this.$route.params.id = this.id;
            this.$swal({
              icon: "success",
              title: "La Ruta se guardo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    back() {
      return this.$router.replace("/Tif/Rutas");
    },
  },
  mounted() {
    this.init();
    this.getEstados();
    this.getBloques();
  },
};
</script>
